import styled from "styled-components";
import NavLink from "./NavLink";
import logo from "../../assets/icons/logo.png";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as TikTok } from "../../assets/icons/tiktok.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin.svg";

const navLinks = [
  {
    type: "text",
    path: "#services",
    text: "Services",
  },
  // {
  //   type: "text",
  //   path: "https://villagestudio.com/",
  //   text: "Studios",
  //   newTab: true,
  // },
  {
    type: "text",
    path: "#contact",
    text: "Contact",
    color: "var(--secondary)",
  },
  {
    type: "image",
    path: "https://www.instagram.com/villagemarketing/",
    image: Instagram,
    newTab: true,
  },
  {
    type: "image",
    path: "https://www.tiktok.com/@villagemarketing",
    image: TikTok,
    newTab: true,
  },
  {
    type: "image",
    path: "https://www.linkedin.com/company/village-marketing/",
    image: LinkedIn,
    newTab: true,
  },
];

const StyledHeader = styled.header`
  height: var(--header-height);
  width: 100%;
  border-bottom: 1px solid white;
  display: flex;
  align-items: center;
`;

const StyledNav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 5px;
  padding: 0 95px 0 127px;
  .logo {
    width: 188px;
    height: auto;
    display: inline-block;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .right ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    li:not(:last-child) {
      margin-right: 50px;
    }
  }

  @media (max-width: 1023px) {
    top: 5px;
    padding: 0;
    justify-content: center;
    .logo {
      width: 180px;
      height: auto;
    }
    .right {
      display: none;
    }
  }
`;

const Header = ({ alt = false }) => {
  return (
    <StyledHeader>
      <StyledNav>
        <div className="left">
          <a href="/" className="logo">
            <img src={logo} alt="Logo" style={{ width: "100%", height: "auto" }} />
          </a>
        </div>
        <div className="right">
          <ul>
            {navLinks.map((link) =>
              alt && link.path[0] === "#" ? null : (
                <li key={link.path}>
                  <NavLink link={link} />
                </li>
              )
            )}
          </ul>
        </div>
      </StyledNav>
    </StyledHeader>
  );
};

export default Header;
